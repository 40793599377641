/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

import NavBar from './NavBar';
import Footer from './Footer';
// import './layout.css';
import '../styles/bootstrap.min.css';
import '../styles/pe-icon-7-stroke.css';
import '../styles/style.css';
import '../styles/red.css';
import '../styles/materialdesignicons.min.css';

const Layout = ({ children, pageContext }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <NavBar siteTitle={data.site.siteMetadata.title} pageContext={pageContext} />
        <main>{children}</main>
        <Footer siteTitle={data.site.siteMetadata.title} />
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
