import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import './NavBar.css';

const LangButton = ({ label, active, onClick }) => (
  <button
    type="button"
    onClick={onClick}
    className="nav-link"
    style={{
      textDecoration: active ? 'underline' : 'none',
    }}
  >
    {label}
  </button>
);

const Navbar = ({ siteTitle, pageContext }) => {
  const [isSticky, setIsSticky] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  useEffect(() => {
    window.addEventListener('scroll', () => {
      const scrolled = document.scrollingElement.scrollTop;
      if (scrolled > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
      return () => {
        window.removeEventListener('scroll');
      };
    });
  });

  return (
    <div className={isSticky ? 'is-sticky' : ''}>
      <nav className="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark">
        <div className="container">
          <a className="navbar-brand logo text-uppercase" href="/">
            <span>oI </span>
            {siteTitle}
          </a>
          <button
            onClick={() => setCollapsed(!collapsed)}
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="mdi mdi-menu" />
          </button>
          <div
            className={
              collapsed
                ? 'collapse navbar-collapse show collapse-navbar'
                : 'collapse navbar-collapse show'
            }
            id="navbarCollapse"
          >
            <ul className="navbar-nav navbar-center" id="mySidenav">
              <li className="nav-item active">
                <a href="#home" className="nav-link">
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a href="#services" className="nav-link">
                  Services
                </a>
              </li>
              <li className="nav-item">
                <a href="#process" className="nav-link">
                  Process
                </a>
              </li>
              <li className="nav-item">
                <a href="#team" className="nav-link">
                  Team
                </a>
              </li>
              <li className="nav-item">
                <a href="#clients" className="nav-link">
                  Clients
                </a>
              </li>
              <li className="nav-item">
                <a href="#technologies" className="nav-link">
                  Technologies
                </a>
              </li>
              {/* <li className="nav-item">
                <a href="#contact" className="nav-link">
                  Contact
                </a>
              </li> */}
              <li className="nav-item d-flex">
                {pageContext.languages.map((language, index) => {
                  const { locale } = pageContext;
                  const handleSetLang = lang => {
                    const { originalPath } = pageContext;
                    const newPathname = `/${lang}${originalPath}`;

                    localStorage.setItem('language', lang);
                    navigate(newPathname);
                  };
                  const separator =
                    index > 0 ? <span className="language-separator"> | </span> : '';
                  return (
                    <>
                      {separator}
                      <LangButton
                        active={language.locale === locale}
                        label={language.locale}
                        onClick={() => handleSetLang(language.locale)}
                      />
                    </>
                  );
                })}
              </li>
            </ul>
            {/* <div className="nav-button ml-auto">
              <ul className="nav navbar-nav navbar-right">
                <li>
                  <button
                    type="button"
                    className="btn btn-custom navbar-btn btn-rounded waves-effect waves-light"
                  >
                    {`Let's talk!`}
                  </button>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
