import React from 'react';
import './Footer.css';

const Footer = ({ siteTitle }) => {
  const currentYear = new Date().getFullYear();
  const currentYearAndSiteTitle = `${currentYear} ${siteTitle}`;
  const copyrightText = (
    <>
      <p className="mb-0">
        {`©${currentYearAndSiteTitle} UG (haftungsbeschränkt), built with `}
        <a href="https://www.gatsbyjs.org">Gatsby</a>
        {`, photo by `}
        <a href="https://unsplash.com/@matteopaga">Matteo Paganelli @ Unsplash</a>
      </p>
    </>
  );
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 margin-t-10 justify-content-start d-flex align-items-end">
            {copyrightText}
          </div>
          <div className="col-lg-4 margin-t-10 justify-content-end d-flex align-items-end">
            <a
              href="https://www.contentful.com/"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              <img
                src="https://images.ctfassets.net/fo9twyrwpveg/7F5pMEOhJ6Y2WukCa2cYws/398e290725ef2d3b3f0f5a73ae8401d6/PoweredByContentful_DarkBackground.svg"
                style={{
                  maxWidth: '100px',
                  width: '100%',
                }}
                alt="Powered by Contentful"
              />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
